/* 会话列表总装车间 */
import _ from 'lodash'
import store from '@/store'
import { messageType } from '@/constant'
import { EaseChatClient } from '@/IM/initwebsdk'

import defaultGroupAvatarUrl from '@/assets/images/avatar/jiaqun2x.png'
import defaultSingleAvatarUrl from '@/assets/images/avatar/theme2x.png'
import { setMessageKey } from '@/utils/handleSomeData'
const { SESSION_MESSAGE_TYPE, CHAT_TYPE, ALL_MESSAGE_TYPE, CUSTOM_TYPE } =
    messageType

import router from '@/router'

//处理最后一条消息预览
const handleLastMsgContent = (msgBody) => {
    const { type, msg } = msgBody
    let resultContent = ''
    //如果消息类型，在预设非展示文本类型中，就返回预设值
    if (SESSION_MESSAGE_TYPE[type]) {
        resultContent = SESSION_MESSAGE_TYPE[type]
    } else if (type === ALL_MESSAGE_TYPE.CUSTOM) {
        //如果为自定义类型消息就匹配自定义消息对应的lastmsg文本
        if (msgBody.customEvent) {
            ;(CUSTOM_TYPE[msgBody.customEvent] &&
                (resultContent = CUSTOM_TYPE[msgBody.customEvent])) ||
                ''
        }
    } else if (msgBody.isRecall) {
        //如果是撤回消息，则展示撤回消息类型文本
        resultContent = '撤回了一条消息'
    } else {
        resultContent = msg
    }
    return resultContent
}
//当前登陆ID
export default async function (corresMessage) {
    /*
     * 1、取到messageList更新后的最后一套消息
     * 2、取会话列表数据进行与当前的messageList进行比对查看messaList中的Key是否已经存在于已有的Conversation中。
     * 3、结合上一步，没有则在追加一个新会话，有则只更新lastmessage.
     */
    const updatedConversation = {}
    const lastMsgBody = corresMessage[corresMessage.length - 1]
    await findIncludesConversation(lastMsgBody)
    //根据传入的消息进入会话列表进行查询
    async function findIncludesConversation(msgBody) {
        if (msgBody) {
            const localConversationList =
                store.state.Conversation.conversationListData
            const listKey = setMessageKey(msgBody)
            //不存在则创建
            if (!localConversationList[listKey]) {
                const newCoversation = await buildConversationItem(
                    'create',
                    msgBody
                )
                updatedConversation[listKey] = newCoversation
            }
            //存在则更新
            else if (localConversationList && localConversationList[listKey]) {
                const theData = _.cloneDeep(
                    store.state.Conversation.conversationListData[listKey]
                )
                const updatedCoversation = await buildConversationItem(
                    'update',
                    msgBody,
                    theData
                )

                updatedConversation[listKey] = updatedCoversation
            }
        }
    }
    //构建会话方法
    async function buildConversationItem(operateType, msgBody, theData) {
        const listKey = setMessageKey(msgBody)
        const { chatType, from, ext, id, time, to, type } = msgBody
        const loginUserId = EaseChatClient.user
        //console.log("%c Line:71 🍿 msgBody", "color:#42b983", msgBody);
        let extData = {}
        if(ext&&ext.msgExt){
            extData = JSON.parse(ext.msgExt)
        }
        // 取到自己的昵称
        const myname = store.state.loginUserInfo.nickname
        let referMe = ''
        // 判断消息中是否提到自己
        if(msgBody.msg&&msgBody.msg.includes(myname)){
            // 提到了自己
            referMe = msgBody.id
        }else{
            referMe = ''
        }
       
        //操作类型为新建
        if (operateType === 'create') {
            // let UserInfo = {}
            // if (chatType === CHAT_TYPE.SINGLE) {
            //     if (msgBody.to == loginUserId) {
            //         // 获取发送方信息
            //         let res = await EaseChatClient.fetchUserInfoById(msgBody.from)
            //         UserInfo = res.data[msgBody.from]
            //     } else {
            //         // 获取接收方信息
            //         let res = await EaseChatClient.fetchUserInfoById(msgBody.to)
            //         UserInfo = res.data[msgBody.to]
            //     }
            // }
            const state = {
                isTop: 2,
                localChat: true,
                conversationType: chatType,
                conversationKey: listKey,
                referMe:referMe,
                conversationInfo: {
                    name: '',
                    avatarUrl:
                        chatType === CHAT_TYPE.SINGLE
                            ? defaultSingleAvatarUrl
                            : defaultGroupAvatarUrl
                },
                fromInfo: {
                    fromId: from,
                    fromName: ''
                },
                targetId: to,
                unreadMessageNum:
                    from === loginUserId || msgBody.read || msgBody.isRecall
                        ? 0
                        : 1,
                latestMessage: {
                    msg: handleLastMsgContent(msgBody),
                    // SESSION_MESSAGE_TYPE[type] ||
                    // (msgBody.isRecall && '撤回了一条消息') ||
                    // msg,
                    type: type,
                    ext: { ...ext }
                },
                latestMessageId: id,
                latestSendTime: time || Date.now()
            }
            if (chatType === CHAT_TYPE.SINGLE) {
                //to字段 暂时选择展示为环信ID
                if(extData.fromUserInfo){
                    state.conversationInfo.name = extData.fromUserInfo.nickName||'未知用户'
                    state.conversationInfo.avatarUrl = extData.fromUserInfo.avatar||''
                }else{
                     let UserInfo = {}
                     if (msgBody.to == loginUserId) {
                            // 获取发送方信息
                            let res = await EaseChatClient.fetchUserInfoById(msgBody.from)
                            UserInfo = res.data[msgBody.from]
                        } else {
                            // 获取接收方信息
                            let res = await EaseChatClient.fetchUserInfoById(msgBody.to)
                            UserInfo = res.data[msgBody.to]
                        }
                    state.conversationInfo.name = UserInfo.nickname
                    state.conversationInfo.avatarUrl = UserInfo.avatarurl
                }
                
            }
            return state
        }
        //操作类型为更新
        if (operateType === 'update') {
            const updatedState = {
                fromInfo: {
                    fromId: from,
                    fromName: ''
                },
                latestMessage: {
                    msg: handleLastMsgContent(msgBody),
                    // SESSION_MESSAGE_TYPE[type] ||
                    // (msgBody.isRecall && '撤回了一条消息') ||
                    // msg,
                    type: type,
                    ext: { ...ext }
                },
                targetId: to,
                latestMessageId: id,
                latestSendTime: time || Date.now(),
                unreadMessageNum: 0,
            }
            if(chatType === CHAT_TYPE.SINGLE){
                // 单聊
                if (
                    router.currentRoute.value.path == '/chat/conversation' &&
                    store.state.Message.currentChatId == msgBody.from
                ) {
                    // 当前会话已打开 不计未读数
                } else {
                    if(referMe){
                        updatedState.referMe = referMe
                    }
                    /* 这里的逻辑为如果from为自己，更新的消息已读，更新的消息为撤回，不计入unreadMessageNum的累加 */
                    updatedState.unreadMessageNum =
                        from === loginUserId || msgBody.read || msgBody.isRecall
                            ? 0
                            : theData.unreadMessageNum + 1
                }
            }else{
                // 群聊
                if (
                    router.currentRoute.value.path == '/chat/conversation' &&
                    store.state.Message.currentChatId == msgBody.to
                ) {
                    // 当前会话已打开 不计未读数
                } else {
                    if(referMe){
                        updatedState.referMe = referMe
                    }
                    /* 这里的逻辑为如果from为自己，更新的消息已读，更新的消息为撤回，不计入unreadMessageNum的累加 */
                    updatedState.unreadMessageNum =
                        from === loginUserId || msgBody.read || msgBody.isRecall
                            ? 0
                            : theData.unreadMessageNum + 1
                }
            }
            
            // if (chatType === CHAT_TYPE.SINGLE) {
            //     //to字段 暂时选择展示为环信ID UserInfo
            //     //console.log("%c Line:178 🍭 UserInfo", "color:#b03734", UserInfo);
            //     updatedState.conversationInfo = { name: '', avatarUrl: '' }
            //     updatedState.conversationInfo.name = UserInfo.nickname
            //     updatedState.conversationInfo.avatarUrl = UserInfo.avatarurl
            // }


          
            return _.assign(theData, updatedState)
        }
    }
    return updatedConversation
}
